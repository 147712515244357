.admin-form{
    height: 512px; /* Set the maximum height of the list to enable scrolling */
    overflow-y: auto; /* Enable vertical scroll if content exceeds the height */
    border: 1px solid #ccc;
    border-radius: 8px;
    margin-right: 10px;
    padding: 16px;
    width: fit-content;
    color: black;
    background-color: #FEFEFE;
}

.admin-view{
    color: black;
}

.flex-row{
    display: flex;
    flex-direction: row;
}

.admin-query-page-body{
    
    
}