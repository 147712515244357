.announcements-container {
  height: 320px; /* Set the maximum height of the list to enable scrolling */
  overflow-y: auto; /* Enable vertical scroll if content exceeds the height */
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-right: 10px;
  padding: 10px;
  width: auto;
}

.announcement-pill {
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  margin: 4px;
  padding: 8px;
  border-radius: 8px;
  background-color: rgba(97, 86, 119, 1) 34%;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  margin-bottom: 8px;
}

.announcement-pill h3 {
  margin-top: 4px;
  margin-bottom: 4px;
}

.announcement-pill p {
  margin-top: 4px;
  margin-bottom: 4px;
}

@media only screen and (max-width: 430px) {
  .announcements-container {
    width: 100%;
    height: 180px;
  }
}
