.placeholder-thumbnail {
  height: 144px;
  width: 256px;
  background-color: lightgrey;
  color: black;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-top: 15%;
  font-size: 40px;
  border-radius: 4px;
}

.placeholder-thumbnail a {
  text-decoration: none;
  color: black;
}

/* DanceSessionCard.css */

.dance-session-card {
  display: flex;
  border: 1px solid #ccc;
  margin: 10px;
  padding: 15px;
  border-radius: 8px;
  background-color: #5c4f72;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
}

.thumbnail {
  flex: 0 0 150px;
  margin-right: 15px;
  justify-content: center;
}

.thumbnail img {
  width: 256px;
  border-radius: 8px;
  border: white solid 1px;
}

.session-details {
  flex: 1;
}

.title {
  font-size: 20px;
  margin-bottom: 10px;
}

.choreographer,
.date {
  font-size: 16px;
  margin-bottom: 2px;
}

a {
  color: #007bff;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

.arrow-button {
  position: relative;
  top: 0px;
  right: 10px;
  font-size: 24px;
  border: #9b97a2;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  color: white;
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px;
}

.arrow-button:hover {
  background-color: rgba(0, 0, 0, 0.2);
}

.arrow-button-inner {
  height: max-content;
}

@media only screen and (max-width: 430px) {
  .dance-session-list {
    width: 100%;
  }
  .thumbnail img {
    width: 100%;
  }
  .thumbnail {
    width: 100%;
    flex: 0 0;
    margin-right: 0;
  }
  .dance-session-card-thumbnail {
    width: auto;
    height: 100%;
  }
  .dance-session-card {
    flex-direction: column;
  }
  .session-details p {
    margin-top: 0px;
  }
  .title {
    margin-bottom: 0px;
    margin-top: 8px;
  }
  .arrow-button {
    position: relative;
    display: block;
    text-align: center;
    top: auto;
    right: auto;
    margin-top: 4px;
  }
}
