.session-page-image {
  width: 400px;
  border: solid white 2px;
  border-radius: 8px;
}

.dance-session-page {
  display: flex;
  flex-direction: row;
}

.dance-session-details {
  margin-right: 10px;
}

.attendees-list {
  height: 300px; /* Set the fixed height of the dance session list */
  overflow-y: auto; /* Enable vertical scroll if content exceeds the height */
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  width: 400px;
  margin-right: 10px;
}

@media only screen and (max-width: 430px) {
  .session-page-image {
    width: 100%;
  }
  .dance-session-page {
    flex-direction: column;
  }
}
