/* Navbar.css */

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;

  padding: 15px 30px;

  color: white;

  border-bottom: white;
  border-bottom-width: 2px;
}

.logo {
  height: 80px; /* Set the height to 100 pixels */
  object-fit: contain; /* Maintain aspect ratio */
}

.login-options {
  margin-top: -2px;
  margin-left: 60%;
  padding: 0;
}

.nav-options {
  display: flex;
  justify-content: center;
  align-items: center;
}

.admin-options {
  display: none;
}

.divider-top {
  width: auto;
  margin-left: 32px;
  margin-right: 32px;
  margin-top: 4px;
  margin-bottom: 4px;

  background-color: #ccc;
  height: 1px;
}

.nav-option {
  margin-right: 10px;
  margin-left: 10px;
  cursor: pointer;
  font-size: 22px;
  transition: color 0.3s ease;
  text-decoration: none;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  align-content: center;
  text-align: center;
}

.nav-text {
  font-size: 22px;
}

.nav-option:hover {
  color: #ff9900; /* Change the color on hover */
}

.nav-option:last-child {
  margin-right: 0;
}

.nav-divider {
  width: 1px;
  height: 24px;
  background-color: white;
}

@media only screen and (max-width: 430px) {
  .navbar {
    flex-direction: column;
  }
  .nav-options {
    font-size: 14px;
  }
  .nav-option {
    width: 33%;
    font-size: 18px;
  }
  .nav-text {
    font-size: 18px;
  }
  .admin-options {
    display: none;
  }
  .nav-page-title {
    font-size: 12px;
  }
}
