body {
  margin: 0;
  font-family: -apple-system, "DM Sans", BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: rgb(97, 86, 119);
  background: linear-gradient(
    90deg,
    rgba(97, 86, 119, 1) 34%,
    rgb(48, 29, 74) 100%
  );
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
