.user-row-display {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 8px;
}

.profile-thumbnail {
  width: 48px;
  height: 48px;
  border-radius: 24px;
  margin-right: 8px;
}

.members-list {
  height: 480px;
  width: 320px;
  border: white solid 1px;
  border-radius: 12px;
  overflow: scroll;
  padding: 8px;
}
