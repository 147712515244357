.footer {
  width: 100%;
}

.divider {
  width: auto;
  margin-left: 32px;
  margin-right: 32px;
  margin-top: 24px;
  margin-bottom: 24px;

  background-color: #ccc;
  height: 1px;
}
