.admin-form{
    height: 512px; /* Set the maximum height of the list to enable scrolling */
    overflow-y: auto; /* Enable vertical scroll if content exceeds the height */
    border: 1px solid #ccc;
    border-radius: 8px;
    margin-right: 10px;
    margin-top: 10px;
    padding: 16px;
    width: fit-content;
    color: black;
    background-color: #FEFEFE;
}

.admin-view{
    color: black;
}

.edit-view{
    color:black;
}

.sessions-table{
    width: 100%;
}

.custom-textfield-container {
    display: flex;
    align-items: center;
}

.custom-textfield-container label {
    margin-right: 10px;
    color: black;
}

/* Adjust the width as needed */
.custom-textfield-container .amplify-input {
    flex-grow: 1;
}