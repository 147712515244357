.profile-pic {
  width: 200px;
  height: 200px;
  border: 1px white solid;
  border-radius: 4px;
}

.user-profile {
  margin-top: 8px;
}

.sessions-attended-list {
  border: white 1px solid;
  border-radius: 12px;
  width: 320px;
  max-height: 320px;
  overflow: scroll;
  padding: 8px;
}

.sessions-attended-list-item {
  display: flex;
  flex-direction: row;
}
