/* DanceSessionList.css */

.dance-session-list {
  height: 512px; /* Set the fixed height of the dance session list */
  overflow-y: auto; /* Enable vertical scroll if content exceeds the height */
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  width: 600px;
}

@media only screen and (max-width: 430px) {
  .dance-session-list {
    width: 100%;
  }
}
