.atttendees-table-frame {
  height: 300px; /* Set the fixed height of the dance session list */
  overflow-y: auto; /* Enable vertical scroll if content exceeds the height */
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  width: 400px;
  margin-right: 10px;
}

.white-bg {
  background-color: white;
  border-radius: 2px;
}

@media only screen and (max-width: 430px) {
  .atttendees-table-frame {
    width: 100%;
  }
}
