/* AttendeesList.css */

.attendees-list {
  height: 300px; /* Set the fixed height of the dance session list */
  overflow-y: auto; /* Enable vertical scroll if content exceeds the height */
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  width: 400px;
  margin-right: 10px;
}

.user-link {
  color: white;
}

.user-link:hover {
  color: rgb(203, 203, 203);
}

.user-link:visited {
  color: white;
}

@media only screen and (max-width: 430px) {
  .attendees-list {
    width: 100%;
    max-height: 200px;
  }
}
