.intro-carousel {
  margin-top: 24px;
  border: solid white 4px;
  border-radius: 8px;
}
.intro-carousel img {
  border-radius: 4px;
}
.intro-carousel h2 {
  position: absolute;
  top: 10px;
  left: 40px;
  font-size: 50px;
  color: black;
}

.float-title {
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: left;
  padding-left: 16px;
}

.float-title-2 {
  position: fixed;
  top: 0;
  width: 100%;
  text-align: left;
  padding-left: 16px;
  color: black;
  text-shadow: 1px 1px 0 #fff, -1px 1px 0 #fff, -1px -1px 0 #fff,
    1px -1px 0 #fff;
}

.float-title-3 {
  position: fixed;
  top: 0;
  width: 100%;
  text-align: center;
  padding-left: 16px;
  color: white;
  text-shadow: 1px 1px 0 #4f315e, -1px 1px 0 #4f315e, -1px -1px 0 #4f315e,
    1px -1px 0 #4f315e;
}

.carousel-element {
  position: relative;
}
