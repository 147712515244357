/* UpcomingEventsList.css */

.upcoming-events-list {
  height: 320px; /* Set the maximum height of the list to enable scrolling */
  overflow-y: auto; /* Enable vertical scroll if content exceeds the height */
  border: 1px solid #ccc;
  border-radius: 8px;

  padding: 10px;
  width: auto;
}

.event-header {
  display: flex;
  justify-content: space-between;
}

.event {
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  padding: 8px;
}

.event-date {
  font-weight: bold;
  margin-bottom: 5px;
}

.event-location,
.event-description {
  font-size: 14px;
  color: #b2b2b2;
}

@media only screen and (max-width: 430px) {
  .upcoming-events-list {
    width: 100%;
  }
}
